import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";

const isLocal = process.env.NODE_ENV === 'development';

if (isLocal) {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <App />,
    document.getElementById("root")
  );
}
