import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const isLocal = process.env.NODE_ENV === 'development';

!!process.env.REACT_APP_SENTRY_DSN && Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: isLocal,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_ENV,
  replaysSessionSampleRate: 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,

  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});