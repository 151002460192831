import type { UploadedPart } from "../types";
import { wait } from "../utils";
import type { ProgressEvent } from "./types";

type Url = string;

export type BeforeUpload = (file: File) => Promise<string>;

export type FetchUrl = (file: File, chunk: number) => Promise<Url>;

interface UploaderOptions {
  config: {
    chunkSize: number;
    maxRetries: number;
    threads?: number;
    timeout?: number;
  },
  callbacks: {
    beforeUpload: BeforeUpload;
    fetchUrl: FetchUrl;
  }
}

function log(message: string, ...args): void {
  console.log(`[MultipartUploader]: ${message}`, ...args);
}

/**
 * Multipart uploader. Handles multipart uploads.
 * @see https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
 */

class MultipartUploader extends EventTarget {
  private aborted = false;
  private offline = false;
  private timeout: number;
  private threads: number;
  private chunkSize: number;
  private maxRetries: number;

  private file: File;
  private parts: Array<number> = [];
  private totalParts = 0;
  private uploadedParts: Array<any> = [];
  private uploadedSize = 0;
  private progressCache: Record<number, number> = {};
  private connections: Record<number, XMLHttpRequest> = {};
  
  private beforeUpload: BeforeUpload;
  private fetchUrl: FetchUrl;

  private handleOnline;
  private handleOffline;

  constructor(options: UploaderOptions) {
    super();

    this.timeout = options.config.timeout || 0;
    this.threads = options.config.threads || 0;
    this.chunkSize = options.config.chunkSize;
    this.maxRetries = options.config.maxRetries;

    this.beforeUpload = options.callbacks.beforeUpload;
    this.fetchUrl = options.callbacks.fetchUrl;

    this.handleOnline = () => {
      if (!this.offline) return;

      this.offline = false;
  
      this.sendNext();
    }

    this.handleOffline = () => {
      this.offline = true;
    }

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  private cleanupListeners() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }
  
  async start (file: File) {
    this.file = file;
    
    this.totalParts = Math.ceil(file.size / this.chunkSize);

    this.parts = new Array(this.totalParts).fill(null).map((_, index) => index).reverse();

    // in this case beforeUpload creates the multipart upload on S3
    this.beforeUpload(file)
      .then(() => {
        this.sendNext();
      })
      .catch(err => {
        this.dispatchEvent(new CustomEvent('error', {
          detail: err
        }));
      });
  }

  private async sendNext (retry = 0) {
    const hasConnections = Object.keys(this.connections).length;

    if (hasConnections >= this.threads) {
      return;
    }

    if (!this.parts.length) {
      if (!hasConnections) {
        this.cleanupListeners();

        this.dispatchEvent(new CustomEvent('success', {
          detail: {
            parts: this.uploadedParts
          }
        }));
      }

      return;
    }

    const part: number|undefined = this.parts.pop();

    if (this.file && part !== undefined) {
      const sentSize: number = part * this.chunkSize;
      const chunk: Blob = this.file.slice(sentSize, sentSize + this.chunkSize);
      
      const url = await this.fetchUrl(this.file, part);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.uploadChunk(url, chunk, part, sendChunkStarted)
        .then(xhr => {
          const ETag = xhr.getResponseHeader("ETag")

          if (ETag) {
            const uploadedPart: UploadedPart = {
              PartNumber: part + 1,
              ETag: ETag.replaceAll('"', ""),
            }

            this.uploadedParts.push(uploadedPart)
          }

          this.sendNext();
        })
        .catch((error: Error) => {
          if (this.aborted) {
            this.parts = [];

            this.cleanupListeners();

            return;
          }

          if (this.offline) {
            // if we're offline, we're not dispatching any error, 
            // but we will wait for the network to be back up
            this.parts.push(part);

            return;
          }

          if (retry < this.maxRetries){
            retry++
            
            //exponential backoff retry before giving up
            log(`Part#${part} failed to upload, backing off ${2 ** retry * 100} before retrying...`, error)
            
            wait(2 ** retry * 100).then(() => {              
              this.parts.push(part);
              this.sendNext(retry);
            })
          } else {
            log(`Part#${part} failed to upload, giving up`, error);

            this.cleanupListeners();

            this.dispatchEvent(new CustomEvent('error', {
              detail: error
            }));
          }
        })
    }
  }

  private handleProgress(part: number, event): void {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);
      const total = this.file.size;

      const progress: ProgressEvent = {
        loaded: sent,
        total: total
      };

      this.dispatchEvent(new CustomEvent('progress', {
        detail: progress
      }));
    }
  }

  private uploadChunk(url: string, chunk: Blob, part: number, sendChunkStarted: Function): Promise<XMLHttpRequest> {
    return new Promise((resolve, reject) => {
      const throwXHRError = (error, part, abortFx) => {    
        delete this.connections[part];
        reject(error);
        window.removeEventListener('offline', abortFx);
      }

      if (!window.navigator.onLine) {
        reject(new Error("System is offline"));
      }

      const xhr = this.connections[part] = new XMLHttpRequest();
      
      xhr.timeout = this.timeout;

      const abortXHR = () => xhr.abort();
      
      sendChunkStarted();

      const progressListener = this.handleProgress.bind(this, part);

      xhr.upload.addEventListener("progress", progressListener);
    
      xhr.addEventListener("error", progressListener)
      xhr.addEventListener("abort", progressListener)
      xhr.addEventListener("loadend", progressListener)

      xhr.open("PUT", url);
      xhr.setRequestHeader('Content-Type', this.file.type);

      xhr.onreadystatechange = (event) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr);

            delete this.connections[part];

            window.removeEventListener('offline', abortXHR);
          }
        }
      };

      xhr.onerror = (error) => {
        console.log(error);
        throwXHRError(error, part, abortXHR);
      }
      xhr.ontimeout = (error) => {
        throwXHRError(error, part, abortXHR);
      }
      xhr.onabort = () => {
        throwXHRError(new Error("Upload canceled by user or system"), part, abortXHR);
      }

      window.addEventListener('offline', abortXHR);

      xhr.send(chunk);
    });
  }

  abort() {
    this.aborted = true;

    Object.values(this.connections).forEach((connection) => {
      connection.abort();
    });
  }
}

export default MultipartUploader;
