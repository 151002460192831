import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
  service: 'content-app',
  env: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_SENTRY_RELEASE,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  sessionSampleRate: 100,
});

export default datadogLogs;