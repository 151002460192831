import "./instrument";
import React from "react";
import * as Sentry from '@sentry/react';
import "./spinner.scss";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AllShoots from "./AllShoots";
import IndividualShoot from "./IndividualShoot";
import RoyaltySplit from "./RoyaltySplit";
import RevenueAgreement from "./RevenueAgreement";
// import FilmmakerApplication from "./FilmmakerApplication";
import PageNotFound from "./PageNotFound";
import ProfileInfo from "./ProfileInfo";
import SendRelease from "./pages/SendRelease/index";
import FootageUpload from "./pages/FootageUpload";

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/filmmakers/:filmmakerId" to="/filmmakers/:filmmakerId/shoots" />
        <SentryRoute path="/filmmakers/:filmmakerId/shoots" component={AllShoots} exact />
        <SentryRoute path="/filmmakers/:filmmakerId/shoots/:shootId" component={IndividualShoot} exact />
        <SentryRoute path="/royaltysplits" component={RoyaltySplit} exact />
        {/* <SentryRoute path="/agreement-pdf" component={AgreementPdf} exact /> */}
        <SentryRoute path="/collaborator-agreement" component={RevenueAgreement} exact />
        <SentryRoute path="/setup-profile" component={ProfileInfo} exact />
        <SentryRoute path="/send-release" component={SendRelease} exact />
        <SentryRoute path="/footage-upload" component={FootageUpload} exact />
        {/* <SentryRoute path="/filmmaker-application" component={FilmmakerApplication} exact /> */}
        <SentryRoute component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}
